<template>
  <v-app>
    <v-container data-app>
      <v-row>
        <v-col style="max-width: 200px;">
          <v-menu
              ref="menu"
              transition="scale-transition"
              right
              bottom
              nudge-bottom="35"
              fixeds
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  style="background: #fff"
                  :value="$root.dateToRus(date)"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="date"
                no-title
                scrollable
                first-day-of-week="1"
                locale="ru"
                :reactive="true"
                @change="load()"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mobile-col-basis" v-for="(info, date) in dates" :key="date">
          <v-card style="height: auto;">
            <v-card-title style="text-transform: capitalize">{{ $moment(date).format('dd') }},
              {{ $root.dateToRus(date, 1) }}
            </v-card-title>
            <v-card-subtitle><b>{{ info.stats[0][0] }}/{{info.stats[1][0]}}</b> работает, у <b>{{ info.stats[0][1] }}/{{ info.stats[1][1] }}</b> выходной
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr v-for="cleaner in info.cleaners" :key="date+cleaner.n_id"
                    :style="cleaner.isVocation ? 'background:#ffecca' : ''">
                  <td @click="$eventBus.$emit('openCleaner', cleaner.n_id)" style="height: 32px;padding: 0 5px 0 10px;cursor: pointer;"
                      :style="Number(cleaner.isNew) ? 'color: red' : ''">
                    <RevenueIcon v-if="!cleaner.isVocation && cleaner.revenue" :revenue="cleaner.revenue.revenue" :hours="cleaner.revenue.hours"/>
                    {{ cleaner.name }}
                    <div v-if="cleaner.worktime" style="color: #797979;font-size: 12px;">{{cleaner.worktime.time_from}} - {{cleaner.worktime.time_to}}</div>
                  </td>
                  <td style="height: 32px;padding: 0 5px;">
                    {{ cleaner.isVocation ? 'Выходной' : '' }}
                    <template v-for="order in cleaner.orders">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <router-link :to="`/orders/${order.n_id}`">
                    <v-chip style="cursor: pointer" small class="mr-2 mb-1">
                      {{ order.datetimecleanObj.time }}
                    </v-chip>
                        </router-link>
                      </span>
                        </template>
                        <span>
                        {{
                            $store.state.cleanTypes[order.type]
                          }} {{ order.address.flattype }} комн, {{
                            order.address.street
                          }}, {{ order.total + $store.state.user.city.country.currency }}, {{order.cleanersCount }} клин
                      </span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Add from "@/views/cleaners/Add";
import NewCleaner from "@/views/cleaners/NewCleaner";
import RevenueIcon from "./RevenueIcon";

export default {
  name: "CleanersCalendar",
  components: {
    RevenueIcon,
    NewCleaner,
  },
  data() {
    return {
      date: this.$moment().format('YYYY-MM-DD'),
      dates: {},
      days: 4,
    }
  },
  methods: {
    load() {
      let dateFrom = this.$moment(this.date).format('YYYY-MM-DD');
      let dateTo = this.$moment(this.date).add(this.days - 1, 'days').format('YYYY-MM-DD');
      this.$store.state.server.request('cleaner/stats', {dateFrom, dateTo}, (data) => {
        this.setDates(data.response, dateFrom);
      });
    },
    getInitials(name) {
      let parts = name.trim().split(' ');
      return parts[0] + ' ' + (parts[1] && parts[1].length >= 2 ? parts[1][0] + '.' : '');
    },
    setDates(cleaners, dateFrom) {
      let dates = {};
      for (let i = 0; i < this.days; ++i) {
        let date = this.$moment(dateFrom).add(i, 'days').format('YYYY-MM-DD');
        dates[date] = {working: 0, vocation: 0, cleaners: [], isNew: 0};
        dates[date].stats = [[0, 0], [0, 0]];
        for (let j in cleaners) {
          let isVocation = cleaners[j].dates && cleaners[j].dates[date] && cleaners[j].dates[date].isVocation ? 1 : 0;
          let worktime = cleaners[j].dates && cleaners[j].dates[date] ? cleaners[j].dates[date].worktime : null;
          let notInList = cleaners[j].dates && cleaners[j].dates[date] && cleaners[j].dates[date].notInList ? 1 : 0;
          if (notInList && !isVocation) continue;
          cleaners[j].isNew = Number(cleaners[j].isNew);
          dates[date].stats[cleaners[j].isNew][isVocation]++;
          dates[date].cleaners.push({
            name: this.getInitials(cleaners[j].name),
            isNew: Number(cleaners[j].isNew),
            n_id: cleaners[j].n_id,
            isVocation: isVocation,
            worktime: worktime,
            revenue: cleaners[j].dates && cleaners[j].dates[date] ? cleaners[j].dates[date].revenue : 0,
            orders: cleaners[j].dates && cleaners[j].dates[date] ? cleaners[j].dates[date].orders : []
          })
        }
        dates[date].cleaners.sort((a, b) => {
          if (a.isVocation > b.isVocation) return 1;
          if (a.isVocation < b.isVocation) return -1;
          if (a.isNew > b.isNew) return 1;
          if (a.isNew < b.isNew) return -1;
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
      }
      this.dates = dates;
    },
  },
  mounted() {
    this.load()
  },
}
</script>

<style scoped>

</style>
